import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import Old2005BlogPage from "../../components/Old2005BlogPage";

const OldBlogPage = () => (
  <Layout>
    <Seo title="Old 2005 Blog—March 2008" />
	<Old2005BlogPage>
		<h2>March 2008</h2>



		<h3 className="blogdate">
		Wednesday, March 19, 2008
		</h3>






		<h4 className="blogitemtitle">
		The world is an amusement park
		</h4>





			<div className="blogitembody">
			I was on my way to the music store today, where I had a quick, introductory listen to <span style={{fontStyle: "italic"}}>Dig, Lazarus, Dig!!!</span> by Nick Cave and the Bad Seeds, and I spotted the following:<br /><ol><li>A man wearing a tee shirt saying "New Mexico: Cleaner Than Regular Mexico".  In "regular" Mexico's defense, I miss your sandy courtyards and chicken barbeques in half-barrels at roadside.<br /></li><li>A lady busking with a 3-or-4-foot-tall harp outside in a mall courtyard.  There were no jesters nearby, but I thought of joining as a bard.<br /></li><li>A Maori fella preaching from a bible to an indifferent, bus stop audience.  He kept peaking at the bible for lines, then refocusing his gaze to the bus-waiters and continuing on as if the words were his own.  I wondered if his gentle accent contributed to the endearing quality of his sermon?<br /></li></ol>
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 4:00 PM &nbsp;
			</div>






		<h3 className="blogdate">
		Tuesday, March 11, 2008
		</h3>






		<h4 className="blogitemtitle">
		0.01 "horse power" insufficient in high winds
		</h4>





			<div className="blogitembody">
			You know you're going to have a <em>great</em> day when you are cycling to work in the morning, and you have to pedal <em>just as hard</em> downhill as you did climbing uphill.
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 11:37 AM &nbsp;
			</div>





		<p>
			<Link to="/blog2005/">Go back to the blog index page</Link>
		</p>
</Old2005BlogPage>
  </Layout>
)

export default OldBlogPage;
